// utils/sendQuote.ts

import { SendQuoteEmailPayload } from '@/types/email';
import { sendEmail } from '@/utils/sendEmail';

export const handleSendQuote = async (
  formData: any,
  clientMap: Map<string, string>,
  clientEmailMap: Map<string, string>,
  setSendLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!formData?.id) {
    alert('No quote available to send.');
    return;
  }

  try {
    setSendLoading(true);
    //console.log('formData', clientEmailMap.get(formData.client_id?.toString() || '') || 'jrixgeromo@gmail.com');
    // Construct the quote email payload
    const emailPayload: SendQuoteEmailPayload = {
      //clientEmail: 'jrixgeromo@gmail.com', // Replace with the actual client email
      //clientEmail: 'jrixhomebased@gmail.com', // Replace with the actual client email
      clientEmail: clientEmailMap.get(formData.client_id?.toString() || '') || 'jrixgeromo@gmail.com', // Replace with the actual client email
      subject: `Quote #${formData.id}`,
      html: `
        <h1>Quote Details</h1>
        <p><strong>Client:</strong> ${
          clientMap.get(formData.client_id?.toString() || '') || 'N/A'
        }</p>
        <p><strong>Total:</strong> $${formData.total?.toFixed(2) || '0.00'}</p>
        <p><strong>Quote Date:</strong> ${formData.date || 'N/A'}</p>
      `,
      metadata: {
        quoteId: formData.id,
      },
    };

    // Call the sendEmail utility function
    const response = await sendEmail(emailPayload, 'quote');

    // Check the response structure
    if (response && response.result && !response.result.error) {
      alert('Quote sent successfully!');
    } else if (response?.result?.error) {
      alert(`Failed to send the quote. Error: ${response.result.error}`);
    } else {
      alert('Failed to send the quote. Unknown error occurred.');
    }
  } catch (error) {
    console.error('Error sending quote:', error);
    alert('Failed to send the quote. Please try again.');
  } finally {
    setSendLoading(false);
  }
};
