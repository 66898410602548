import { SendInvoiceEmailPayload } from '@/types/email';
import { sendEmail } from '@/utils/sendEmail';
import html2pdf from 'html2pdf.js';

export const handleSendInvoice = async (
  formData: any,
  clientMap: Map<string, string>,
  clientEmailMap: Map<string, string>,
  setSendLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!formData?.id) {
    alert('No invoice available to send.');
    return;
  }

  try {
    setSendLoading(true);

    // Ensure formData.items is an array
    const invoiceItems = Array.isArray(formData.items)
      ? formData.items.map((item: any) => ({
          description: item.description || 'No description provided',
          quantity: item.quantity || 1,
          price: item.price || 0, // Ensure price is included
        }))
      : []; // Fallback to an empty array if items is not an array

    // Generate the PDF invoice
    const generatePDF = async () => {
      const container = document.createElement('div');
      container.innerHTML = `
        <div style="color: black; font-family: Arial, sans-serif; line-height: 1.6;">
          <h1>Invoice Details</h1>
          <p><strong>Client:</strong> ${
            clientMap.get(formData.client_id?.toString() || '') || 'N/A'
          }</p>
          <p><strong>Total:</strong> $${formData.total || '0.00'}</p>
          <p><strong>Invoice Date:</strong> ${formData.date || 'N/A'}</p>
          <p><strong>Due Date:</strong> ${formData.dueDate || 'N/A'}</p>
          <h3>Items:</h3>
          <table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              ${invoiceItems
                .map(
                  (item: any) =>
                    `<tr>
                      <td>${item.description}</td>
                      <td>${item.quantity}</td>
                      <td>$${item.price.toFixed(2)}</td>
                      <td>$${(item.quantity * item.price).toFixed(2)}</td>
                    </tr>`
                )
                .join('')}
            </tbody>
          </table>
        </div>
      `;
      return await html2pdf().from(container).outputPdf('datauristring');
    };

    const pdfDataUri = await generatePDF();
    const pdfBase64 = pdfDataUri.split(',')[1]; // Extract the Base64 data from the Data URI

    // Construct the invoice email payload
    const emailPayload: SendInvoiceEmailPayload = {
      clientEmail: 'jrixhomebased@gmail.com',
      //clientEmail: clientEmailMap.get(formData.client_id?.toString() || '') || 'jrixhomebased@gmail.com',
      subject: `Invoice #${formData.id}`,
      html: `
        <h1>Invoice Details</h1>
        <p><strong>Client:</strong> ${
          clientMap.get(formData.client_id?.toString() || '') || 'N/A'
        }</p>
        <p><strong>Total:</strong> $${formData.total || '0.00'}</p>
        <p><strong>Invoice Date:</strong> ${formData.date || 'N/A'}</p>
        <p><strong>Due Date:</strong> ${formData.dueDate || 'N/A'}</p>
      `,
      metadata: {
        invoiceId: formData.id,
      },
      invoice: {
        id: formData.id,
        date: formData.date || new Date().toISOString(),
        dueDate: formData.dueDate || 'N/A',
        amount: formData.total || 0,
        currency: 'USD',
        items: invoiceItems,
      },
      attachments: [
        {
          filename: `Invoice_${formData.id}.pdf`,
          content: pdfBase64,
          encoding: 'base64',
        },
      ],
    };

    // Call the sendEmail utility function
    const response = await sendEmail(emailPayload, 'invoice');

    if (response && response.result && !response.result.error) {
      alert('Invoice sent successfully!');
    } else if (response?.result?.error) {
      alert(`Failed to send the invoice. Error: ${response.result.error}`);
    } else {
      alert('Failed to send the invoice. Unknown error occurred.');
    }
  } catch (error) {
    console.error('Error sending invoice:', error);
    alert('Failed to send the invoice. Please try again.');
  } finally {
    setSendLoading(false);
  }
};
