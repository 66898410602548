// utils/duplicateQuote.ts

import { removeTypename } from '@/utils/removeTypename';

interface DuplicateQuoteParams {
  formData: any;
  currentUser: any;
  createQuote: (options: { variables: { input: any } }) => Promise<any>;
  navigate: (path: string) => void;
}

export const handleDuplicateQuote = async ({
  formData,
  currentUser,
  createQuote,
  navigate,
}: DuplicateQuoteParams) => {
  if (!formData) {
    alert('No quote to duplicate.');
    return;
  }

  try {
    // Safely destructure `__typename` and `items`
    const { __typename, items, ...cleanedData } = formData as {
      __typename?: string;
      items?: any[];
    };

    // Remove `__typename` from items
    const cleanedItems =
      typeof items === 'string'
        ? JSON.parse(items).map(removeTypename)
        : Array.isArray(items)
          ? items.map(removeTypename)
          : [];

    const duplicatedData = {
      ...removeTypename(cleanedData),
      items: JSON.stringify(cleanedItems), // Ensure items are serialized as JSON
      id: undefined, // Clear ID to create a new quote
      date: new Date().toISOString().split('T')[0], // Use current date
      status: 'Draft', // Set status to 'Draft'
      company_id: currentUser.user_metadata?.company_id, // Add company_id
      created_by: currentUser.id, // Add created_by
    };

    const { data } = await createQuote({
      variables: { input: duplicatedData },
    });

    if (data) {
      alert('Quote duplicated successfully!');
      navigate('/quotes');
    }
  } catch (err) {
    console.error('Error duplicating quote:', err);
    alert('Failed to duplicate the quote. Please try again.');
  }
};
